import blogReducer from "./blogReducer";
import basinReducer from "./basinReducer";
import vajinusmusReducer from "./vajinusmusReducer";
import sssReducer from "./sssReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
const rootReducer = combineReducers({
    multilanguage: createMultilanguageReducer({ currentLanguageCode: "tr" }),
    blogData: blogReducer,
    vajinusmusData: vajinusmusReducer,
    sssData: sssReducer,
    basinData: basinReducer,
});
export default rootReducer;
