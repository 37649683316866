import SectionTitle from "../../../components/section-title";
import AppointmentForm from "../../../components/appointment-form";

const AppointmentContainer = () => {
    return (
        <section className="appointment-area bg-gray">
            <div className="appointment-form-style1">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="appointment-form section-title">
                                <h1 className="mb-4 title"><span>Ankara Kadın Doğum Uzmanı</span></h1>
                                <SectionTitle
                                    subTitle="HER ZAMAN SİZE YARDIMCI OLMAYA HAZIRIM"
                                    title="<span>İletişime</span> Geçin"
                                />
                                <AppointmentForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AppointmentContainer;
