import Button from "../button";
import PropTypes from "prop-types";

const Intro = ({ data }) => {
    return (
        <div className="intro-section">
            <div
                className="background-layer"
                style={{
                    backgroundImage: `url(${
                        process.env.PUBLIC_URL + data.backgroundImage
                    })`,
                }}
            ></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="slider-content">
                            <p className="text animated delay1">
                                {data.subTitle}
                            </p>
                            <h2
                                className="title animated delay2"
                                dangerouslySetInnerHTML={{ __html: data.title }}
                            />
                            <Button
                                path={process.env.PUBLIC_URL + "/iletisim"}
                                classOption="btn btn-danger me-3 animated delay1"
                                text="İletişime Geçin"
                            />
                            {/* <Button
                                path={process.env.PUBLIC_URL + "/"}
                                classOption="btn btn-outline-secondary animated delay2"
                                text="our services"
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Intro.propTypes = {
    data: PropTypes.object,
};

export default Intro;
