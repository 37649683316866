import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Layout from "../../layouts/index.jsx";
import Header from "../../layouts/header";
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon,
  } from "react-share";


import Footer from "../../layouts/footer";

import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
  const cleanAndReplace = (str) => {
    return str
      .toLowerCase()
      .replace(/ğ/g, "g")
      .replace(/ü/g, "u")
      .replace(/ş/g, "s")
      .replace(/ı/g, "i")
      .replace(/ö/g, "o")
      .replace(/ç/g, "c")
      .replace(/\s+/g, "-");
  };
const VajinusmusDetails = ({ vajinusmus }) => {
    let { name } = useParams();
    const [vajinusmuss, setVajinusmus] = useState("");

    setTimeout(() => (document.title = vajinusmuss.name), 100);

 
  
    useEffect(() => {
      let data = vajinusmus.filter(
        (key) => cleanAndReplace(key.name) === cleanAndReplace(name)
      );
  
      if (data.length > 0) {
        setVajinusmus(data[0]);
        
      }
    }, [vajinusmus, name]);
    return (
        <React.Fragment>
            <Layout>
                {/* <SEO title="Dr. Müjdegül Zayıfoğlu Karaca" /> */}
                <div className="wrapper">
                    <Header />
                    <div className="container">
                        {vajinusmuss != "" ? (
                            <div>
                                <section className="page-title centred">
                                    <div className="auto-container ">
                                        <div className="content-box mt-5">
                                            <h1>{vajinusmuss.name}</h1>
                                            <ul className="bread-crumb clearfix">
                                                <li>{vajinusmuss.name}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </section>
                                {/*End Page Title*/}
                                {/* sidebar-page-container */}
                                <section className="sidebar-page-container blog-details">
                                    <div className="auto-container">
                                        <div className="row clearfix">
                                            <div className="col-lg-8 col-md-12 col-sm-12 content-side">
                                                <div className="blog-details-content">
                                                    <div className="news-block-one">
                                                        <div className="inner-box">
                                                            <figure className="image-box">
                                                                <img
                                                                    src={`https://api.mujdegulkaraca.com.tr/uploads/${vajinusmuss.img}`}
                                                                    alt
                                                                />
                                                            </figure>
                                                            <div className="lower-content">
                                                                <span className="feature">
                                                                    {
                                                                        vajinusmuss.categories
                                                                    }
                                                                </span>
                                                                <h2>
                                                                    {
                                                                        vajinusmuss.name
                                                                    }
                                                                </h2>
                                                                <ul className="post-info clearfix">
                                                                    <li>
                                                                        <img
                                                                            style={{
                                                                                height:
                                                                                    "3vh",
                                                                            }}
                                                                            src="/img/blog/mujde.jpg"
                                                                            alt
                                                                        />
                                                                        DR.
                                                                        Müjdegül
                                                                        KARACA
                                                                    </li>
                                                                    <li>
                                                                        {
                                                                            vajinusmuss.date
                                                                        }
                                                                    </li>
                                                                </ul>
                                                                <div className="text mt-3">
                                                                    <p>
                                                                        {ReactHtmlParser(
                                                                            vajinusmuss.info
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div className="post-share-option">
                <h4>Paylaş:</h4>
              {/* Facebook Share Button */}
              <FacebookShareButton
                url={`https://www.drmujdegul.com/vajinusmus-detay/${cleanAndReplace(vajinusmuss.name)}`}
                quote={vajinusmuss.name}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>

              {/* Twitter Share Button */}
              <TwitterShareButton
                url={`https://www.drmujdegul.com/vajinusmus-detay/${cleanAndReplace(vajinusmuss.name)}`}
                title={vajinusmuss.name}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>
                            </div>
                        ) : (
                            <>blog bulunamadı</>
                        )}
                    </div>
                    <Footer />
                </div>
            </Layout>
        </React.Fragment>
    );
};

VajinusmusDetails.propTypes = {
    vajinusmus: PropTypes.array,
};

const mapStateToProps = (state) => {
    return {
        vajinusmus: state.vajinusmusData.vajinusmus,
    };
};

export default connect(mapStateToProps)(VajinusmusDetails);
