import React from "react";
import AboutData from "../../data/about.json";
import ServiceData from "../../data/service.json";
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";
import { Link } from "react-router-dom";
import { flatDeep, slugify, containsObject } from "../../utils";
import AboutAddress from "../../components/about-address";

const AboutContainer = () => {
    const cats = ServiceData.map((item) => {
        return item.categories;
    });
    let singleCatArray = flatDeep(cats);

    singleCatArray.forEach((cat) => {});
    return (
        <div className="about-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <p className="text-pra" data-aos="fade-up">
                            {AboutData[0].pageTitle}
                        </p>
                        <div
                            className="service-list-content"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <h4 className="title">{AboutData[1].title}</h4>
                            {AboutData[1].excerpt.map((single, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="desc"
                                        dangerouslySetInnerHTML={{
                                            __html: single,
                                        }}
                                    />
                                );
                            })}
                        </div>
                        <div
                            className="office-center-content"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <h4 className="title">Uzmanlık Alanları</h4>
                            <div
                                className="service-list"
                                data-aos="fade-up"
                                data-aos-duration="1200"
                            >
                                <p>
                                    <li>Cinsel Sağlık</li>
                                    <li>T&uuml;p Bebek</li>
                                    <li>Molek&uuml;ler Biyoloji ve Genetik</li>
                                    <li>İnfertilite</li>
                                    <li>Kadın Sağlığı</li>
                                    <li>Ergen Sağlığı</li>
                                    <li>Menopoz</li>
                                    <li>Tekrarlayan Gebelik Kaybı</li>
                                    <li>Polikistik Over Sendromu</li>
                                    <li>HPV</li>
                                    <li>Cinsel Yolla Bulaşan Hastalıklar</li>
                                    <li>İdrar Kaçırma</li>{" "}
                                    <li>Genital Estetik</li>
                                </p>
                            </div>
                        </div>
                        <div
                            className="office-center-content"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <h4 className="title">
                                Bilimsel Kuruluşlara &Uuml;yelikler
                            </h4>
                            <p>1- T&uuml;rk Tabipler Birliği</p>
                            <p>
                                2- T&uuml;rkiye Jinekoloji ve Obstetri Derneği,
                                Ankara (TJOD)
                            </p>
                            <p>3-T&uuml;rkiye Jinekolojik Onkoloji Derneği</p>
                            <p>4- T&uuml;rkiye Tıbbi Biyoloji Derneği</p>
                            <p>5- Ankara &Uuml;niversitesi Mezunlar Derneği</p>
                            <p>
                                6- T&uuml;rkiye Aile Planlaması Derneği,
                                gen&ccedil;lik kolu
                            </p>
                            <h4 className="title">Mesleki Eğitimler</h4>

                            <p>Cinsel Terapiler Eğitimi (2016-2017, CİSED)</p>
                            <p>
                                &Uuml;remeye Yardımcı Teknikler Eğitimi (2017,
                                Etlik Doğumevi)
                            </p>
                            <p>
                                Abd&uuml;lkadir &Ouml;zbek Psikodrama
                                Enstit&uuml;s&uuml;, Psikodrama Yardımcı
                                Y&ouml;neticisi (2017-2022, Halen ileri aşama
                                eğitimi devam etmektedir)
                            </p>

                            <h4 className="title">Öğrenim Durumu</h4>
                            <div
                                className="office-address-content"
                                data-aos="fade-up"
                                data-aos-duration="1200"
                            >
                                <table width="1023">
                                    <tbody>
                                        <tr>
                                            <td width="143">
                                                <p>
                                                    <strong>Derece</strong>
                                                </p>
                                            </td>
                                            <td width="220">
                                                <p>
                                                    <strong>
                                                        B&ouml;l&uuml;m/Program
                                                    </strong>
                                                </p>
                                            </td>
                                            <td width="280">
                                                <p>
                                                    <strong>
                                                        &Uuml;niversite
                                                    </strong>
                                                </p>
                                            </td>
                                            <td width="87">
                                                <p>
                                                    <strong>Yıl</strong>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="123">
                                                <p>Lisans/Y.Lisans</p>
                                            </td>
                                            <td width="180">
                                                <p>&nbsp;Tıp Fak&uuml;ltesi</p>
                                            </td>
                                            <td width="255">
                                                <p>
                                                    Ankara&nbsp;
                                                    &Uuml;niversitesi
                                                </p>
                                            </td>
                                            <td width="66">
                                                <p>2004</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="123">
                                                <p>Tıpta Uzmanlık</p>
                                            </td>
                                            <td width="180">
                                                <p>
                                                    Kadın Hastalıkları ve Doğum
                                                </p>
                                            </td>
                                            <td width="255">
                                                <p>
                                                    Dr. Zekai Tahir Burak Kadın
                                                    Sağlığı Eğitim ve Araştırma
                                                    Hastanesi
                                                </p>
                                            </td>
                                            <td width="66">
                                                <p>2010</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="123">
                                                <p>Doktora</p>
                                            </td>
                                            <td width="180">
                                                <p>Tıbbi Biyoloji ve Genetik</p>
                                            </td>
                                            <td width="255">
                                                <p>Gazi &Uuml;niversitesi</p>
                                            </td>
                                            <td width="66">
                                                <p>2016</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 className="title">Çalışılan Kurumlar</h4>
                            <div
                                className="office-address-content"
                                data-aos="fade-up"
                                data-aos-duration="1200"
                            >
                                <br />
                                <table width="1003">
                                    <tbody>
                                        <tr>
                                            <td width="190">
                                                <p>
                                                    <strong>
                                                        G&ouml;rev Unvanı
                                                    </strong>
                                                </p>
                                            </td>
                                            <td width="800">
                                                <p>
                                                    <strong>
                                                        G&ouml;rev Yeri
                                                    </strong>
                                                </p>
                                            </td>
                                            <td width="100">
                                                <p>
                                                    <strong>Yıl</strong>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="160">
                                                <p>Araştırma G&ouml;revlisi</p>
                                            </td>
                                            <td width="359">
                                                <p>
                                                    &nbsp;Dr. Zekai Tahir Burak
                                                    Kadın Sağlığı Eğitim ve
                                                    Araştırma Hastanesi
                                                </p>
                                            </td>
                                            <td width="84">
                                                <p>2005-2010</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="160">
                                                <p>
                                                    Kadın Hastalıkları ve Doğum
                                                    Uzmanı
                                                </p>
                                            </td>
                                            <td width="359">
                                                <p>
                                                    &nbsp;Konya, Cihanbeyli
                                                    Devlet Hastanesi
                                                </p>
                                            </td>
                                            <td width="84">
                                                <p>2010-2012</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="160">
                                                <p>
                                                    Kadın Hastalıkları ve Doğum
                                                    Uzmanı
                                                </p>
                                            </td>
                                            <td width="359">
                                                <p>
                                                    &nbsp;THSK Bulaşıcı Olmayan
                                                    Hastalıklar, Programlar ve
                                                    Kanser Başkan Yardımcılığı,
                                                    Kanser Dairesi
                                                </p>
                                            </td>
                                            <td width="84">
                                                <p>2012-2017</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="160">
                                                <p>
                                                    Kadın Hastalıkları ve Doğum
                                                    Uzmanı (Başasistan)
                                                </p>
                                            </td>
                                            <td width="359">
                                                <p>
                                                    Dr. Zekai Tahir Burak Kadın
                                                    Sağlığı Eğitim ve Araştırma
                                                    Hastanesi
                                                </p>
                                            </td>
                                            <td width="84">
                                                <p>2017-2019</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="160">
                                                <p>
                                                    Kadın Hastalıkları ve Doğum
                                                    Uzmanı (Başasistan)
                                                </p>
                                            </td>
                                            <td width="359">
                                                <p>
                                                    Ankara Bilkent Şehir
                                                    Hastanesi
                                                </p>
                                            </td>
                                            <td width="84">
                                                <p>2019-2023</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-md-6" data-aos="fade-up">
                                    <div className="gallery-item mb-30">
                                        <LightgalleryProvider>
                                            <LightgalleryItem
                                                group="any"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    AboutData[3].gallery
                                                        .imageOne
                                                }
                                            >
                                                <div className="thumb">
                                                    <div className="lightbox-image">
                                                        <img
                                                            src={
                                                                process.env
                                                                    .PUBLIC_URL +
                                                                AboutData[3]
                                                                    .gallery
                                                                    .imageOne
                                                            }
                                                            alt="gallery"
                                                        />
                                                    </div>
                                                    <div className="overlay">
                                                        <i className="icofont-plus"></i>
                                                    </div>
                                                </div>
                                            </LightgalleryItem>
                                        </LightgalleryProvider>
                                    </div>
                                </div>
                                <div
                                    className="col-md-6"
                                    data-aos="fade-up"
                                    data-aos-duration="600"
                                >
                                    <div className="gallery-item mb-30">
                                        <LightgalleryProvider>
                                            <LightgalleryItem
                                                group="any"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    AboutData[3].gallery
                                                        .imageTwo
                                                }
                                            >
                                                <div className="thumb">
                                                    <div className="lightbox-image">
                                                        <img
                                                            src={
                                                                process.env
                                                                    .PUBLIC_URL +
                                                                AboutData[3]
                                                                    .gallery
                                                                    .imageTwo
                                                            }
                                                            alt="gallery"
                                                        />
                                                    </div>
                                                    <div className="overlay">
                                                        <i className="icofont-plus"></i>
                                                    </div>
                                                </div>
                                            </LightgalleryItem>
                                        </LightgalleryProvider>
                                    </div>
                                </div>
                                <div
                                    className="col-md-12"
                                    data-aos="fade-up"
                                    data-aos-duration="1200"
                                >
                                    <div className="gallery-item">
                                        <LightgalleryProvider>
                                            <LightgalleryItem
                                                group="any"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    AboutData[3].gallery
                                                        .imageThree
                                                }
                                            >
                                                <div className="thumb">
                                                    <div className="lightbox-image">
                                                        <img
                                                            src={
                                                                process.env
                                                                    .PUBLIC_URL +
                                                                AboutData[3]
                                                                    .gallery
                                                                    .imageThree
                                                            }
                                                            alt="gallery"
                                                        />
                                                    </div>
                                                    <div className="overlay">
                                                        <i className="icofont-plus"></i>
                                                    </div>
                                                </div>
                                            </LightgalleryItem>
                                        </LightgalleryProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutContainer;
