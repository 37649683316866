import SocialIcon from "../../components/social-icon";
import Button from "../../components/button";
import Logo from "../../components/logo";
import MainMenu from "../../components/menu/main-menu";
import HomeData from "../../data/home.json";
import HeaderContactInfo from "../../components/header-contact-info";
import { Fragment, useEffect, useState } from "react";
import MobileMenu from "../../components/menu/mobile-menu";
import MenuOverlay from "../../components/menu/menu-overlay";
import { changeLanguage } from "redux-multilanguage";
import PropTypes from "prop-types";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";

const Header = (props) => {
    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow((prev) => !prev);
    };
    const [scroll, setScroll] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);
    useEffect(() => {
        const header = document.querySelector(".sticky-header");
        setHeaderTop(header.offsetTop);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = ({}) => {
        setScroll(window.scrollY);
    };
    return (
        <Fragment>
            <header className="header">
                <div className="header-top d-none d-lg-block">
                    <div className="container">
                        <div className="row row-cols-2">
                            <div className="col">
                                <p>
                                    <i className="icofont-google-map"></i>{" "}
                                    <span>ADRES:</span> Mustafa Kemal Mah. 2141.
                                    Cd. No:15/1 Çankaya/ANKARA
                                </p>
                            </div>

                            <div className="col">
                                {/* <div className="">
                                    <div
                                        className="language"
                                        role="radiogroup"
                                        aria-labelledby="language-switcher1"
                                    >
                                        <div className="language__container--left language__container--fr">
                                            <input
                                                onChange={(e) => {
                                                    props.dispatch(
                                                        changeLanguage(
                                                            e.target.value
                                                        )
                                                    );
                                                }}
                                                className="language__control"
                                                type="radio"
                                                id="language1-1"
                                                aria-label="en"
                                                name="en"
                                                value={"en"}
                                                checked={
                                                    props.currentLanguageCode ==
                                                    "en"
                                                        ? true
                                                        : false
                                                }
                                            />
                                            <label
                                                className="language__label"
                                                htmlFor="language1-1"
                                            >
                                                EN
                                                <span className="hidden"></span>
                                            </label>
                                        </div>
                                        <div className="language__container--right language__container--en">
                                            <input
                                                onChange={(e) => {
                                                    props.dispatch(
                                                        changeLanguage(
                                                            e.target.value
                                                        )
                                                    );
                                                }}
                                                className="language__control"
                                                type="radio"
                                                id="language1-2"
                                                aria-label="tr"
                                                name="tr"
                                                value={"tr"}
                                                checked={
                                                    props.currentLanguageCode ==
                                                    "tr"
                                                        ? true
                                                        : false
                                                }
                                            />
                                            <label
                                                className="language__label"
                                                htmlFor="language1-2"
                                            >
                                                TR
                                                <span className="hidden">
                                                    {" "}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div> */}

                                <ul className="social-links text-end">
                                    <li>
                                        <SocialIcon
                                            path="https://twitter.com/mujdegulkaraca"
                                            icon="icofont-twitter"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            path="https://www.facebook.com/"
                                            icon="icofont-facebook"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            path="https://www.instagram.com/drmujdegulzayifoglukaraca/"
                                            icon="icofont-instagram"
                                        />
                                    </li>

                                    <li>
                                        <SocialIcon
                                            path="https://www.youtube.com/"
                                            icon="icofont-play-alt-1"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`header-middle mobile-sticky`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="header-middle-content">
                                    <div className="header-logo">
                                        <Logo
                                            image={`${process.env.PUBLIC_URL}/img/mujdegul_logo.webp`}
                                        />
                                    </div>
                                    <ul className="media-wrap d-none d-lg-flex">
                                        <li className="media media-list">
                                            <span className="media-icon">
                                                <i className="icofont-clock-time"></i>
                                            </span>
                                            <div className="media-content">
                                                <span className="media-sub-heading">
                                                    Çalışma Saatleri
                                                </span>
                                                <span className="media-heading">
                                                    Pazt - Cuma: 09.00 - 17.00{" "}
                                                </span>
                                            </div>
                                        </li>
                                        <li className="media media-list">
                                            <span className="media-icon">
                                                <i className="icofont-ui-call"></i>
                                            </span>
                                            <div className="media-content">
                                                <span className="media-sub-heading">
                                                    Telefon
                                                </span>
                                                <a
                                                    className="media-heading"
                                                    href="tel:+905344250343"
                                                >
                                                    +90 534 425 03 43
                                                </a>
                                            </div>
                                        </li>
                                        <li className="media media-list">
                                            <span className="media-icon">
                                                <i className="icofont-envelope"></i>
                                            </span>
                                            <div className="media-content">
                                                <span className="media-sub-heading">
                                                    Email
                                                </span>
                                                <a
                                                    className="media-heading"
                                                    href="mailto:bilgi@drmujdegul.com"
                                                >
                                                    bilgi@drmujdegul.com
                                                </a>
                                            </div>
                                        </li>
                                    </ul>

                                    <div className="mobile-menu-toggle d-lg-none">
                                        <button
                                            onClick={onCanvasHandler}
                                            className="offcanvas-toggle"
                                        >
                                            <svg viewBox="0 0 800 600">
                                                <path
                                                    d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                                                    id="top"
                                                ></path>
                                                <path
                                                    d="M300,320 L540,320"
                                                    id="middle"
                                                ></path>
                                                <path
                                                    d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                                                    id="bottom"
                                                    transform="translate(480, 320) scale(1, -1) translate(-480, -318)"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-bottom d-none d-lg-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <MainMenu />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`header-bottom sticky-header d-none d-lg-block ${
                        scroll > headerTop ? "sticky" : ""
                    }`}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <MainMenu />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <MenuOverlay show={ofcanvasShow} />
            <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
        </Fragment>
    );
};

Header.propTypes = {
    dispatch: PropTypes.func,
    strings: PropTypes.object,
    currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(Header));
