import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";

import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon,
  } from "react-share";

const cleanAndReplace = (str) => {
  return str
    .toLowerCase()
    .replace(/ğ/g, "g")
    .replace(/ü/g, "u")
    .replace(/ş/g, "s")
    .replace(/ı/g, "i")
    .replace(/ö/g, "o")
    .replace(/ç/g, "c")
    .replace(/\s+/g, "-");
};
const BlogDetailsWrap = ({ blogs }) => {
  let { name } = useParams();
  const [blog, setBlog] = useState("");
  const [relatedBlog, setRelatedBlog] = useState([]);

  setTimeout(() => (document.title = blog.name), 100);
  const generateRandomBlogs = (blogs, currentBlog, count = 4) => {
    const shuffled = blogs
      .filter((key) => cleanAndReplace(key.name) !== cleanAndReplace(currentBlog.name))
      .sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  useEffect(() => {
    let data = blogs.filter(
      (key) => cleanAndReplace(key.name) === cleanAndReplace(name)
    );

    if (data.length > 0) {
      setBlog(data[0]);
      const randomBlogs = generateRandomBlogs(blogs, data[0]);
      setRelatedBlog(randomBlogs);
    }
  }, [blogs, name]);

  return (
    <div className="blog-details-wrpa">
      <div className="content">
        {blog != "" ? (
          <div>
            <section className="page-title centred">
              <div className="auto-container">
                <div className="content-box">
                  <h1>{blog.name}</h1>
                </div>
              </div>
            </section>
            {/*End Page Title*/}
            {/* sidebar-page-container */}
            <section className="sidebar-page-container blog-details">
              <div className="auto-container">
                <div className="row ">
                  <div className="col-lg-8 col-md-12 col-sm-12 ">
                    <div className="blog-details-content">
                      <div className="news-block-one">
                        <div className="inner-box">
                          <span className="feature category">
                            {blog.categories}
                          </span>{" "}
                          <ul className="post-info clearfix mb-3">
                            <li>
                              <img
                                style={{
                                  height: "3vh",
                                }}
                                src="/img/blog/mujde.jpg"
                                alt=""
                              />
                              DR. Müjdegül KARACA
                            </li>
                            {/* <li>Nisan 18, 2020</li> */}
                          </ul>
                          <figure className="image-box d-flex justify-content-center">
                            <img
                              src={`https://api.mujdegulkaraca.com.tr/uploads/${blog.img}`}
                              alt=""
                            />
                          </figure>
                          <div className="lower-content">
                            <div className="text">
                              <p>{ReactHtmlParser(blog.info)}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="post-share-option"></div>
                    </div>
                  </div>
                  <div className="col-lg-3 offset-lg-1 col-md-12 col-sm-12 ">
                    <div className="sidebar">
                      <div className="sidebar-widget sidebar-post">
                        <div className="widget-title">
                          <h5>Diğer Yazılarım</h5>
                        </div>
                        {relatedBlog.map((key, index) => {
                          return (
                            <div className="post-inner">
                              <div className="post">
                                <figure className="post-thumb">
                                  <a
                                    href={`/blog-detay/${cleanAndReplace(
                                      key.name
                                    )}`}
                                  >
                                    <img
                                      src={`https://api.mujdegulkaraca.com.tr/uploads/${key.img}`}
                                      alt=""
                                    />
                                  </a>
                                </figure>
                                <h5>
                                  <a href={`/blog-detay/${cleanAndReplace(
                                      key.name
                                    )}`}>
                                    {key.name}
                                  </a>
                                </h5>
                               
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="sidebar-widget sidebar-social"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="post-share-option">
                <h4>Paylaş:</h4>
              {/* Facebook Share Button */}
              <FacebookShareButton
                url={`https://www.drmujdegul.com/blog-detay/${cleanAndReplace(blog.name)}`}
                quote={blog.name}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>

              {/* Twitter Share Button */}
              <TwitterShareButton
                url={`https://www.drmujdegul.com/blog-detay/${cleanAndReplace(blog.name)}`}
                title={blog.name}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>
          </div>
        ) : (
          <>blog bulunamadı</>
        )}
      </div>

   

    </div>
  );
};

BlogDetailsWrap.propTypes = {
  blogs: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    blogs: state.blogData.blogs,
  };
};

export default connect(mapStateToProps)(BlogDetailsWrap);
