export const FETCH_SSS_SUCCESS = "FETCH_SSS_SUCCESS";

const fetchSSSSuccess = (sss) => ({
    type: FETCH_SSS_SUCCESS,
    payload: sss,
});

// fetch products
export const fetchSSS = (sss) => {
    return (dispatch) => {
        dispatch(fetchSSSSuccess(sss));
    };
};
