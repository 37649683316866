import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import Breadcrumb from "../containers/global/breadcrumb/index.jsx";
import BlogDetailsContainer from "../containers/blog/blog-details";
import CommentContainer from "../containers/comment-container/index.jsx";
import Footer from "../layouts/footer";
import BlogData from "../data/blog.json";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";

const BasinDetails = ({ basin }) => {
    let { basinNo } = useParams();
    const [basins, setBasin] = useState("");
    const [relatedBlog, setRelatedBlog] = useState([]);

    setTimeout(() => (document.title = basins.name), 100);
    useEffect(() => {
        let data = basin.filter(
            (key) => key.basinNo.toLowerCase() == basinNo.replaceAll(" ", "-")
        );
        if (data.length > 0) {
            setBasin(data[0]);
        }
        let relatedBlog = basin.filter(
            (key) => key.basinNo.toLowerCase() != basinNo.replaceAll(" ", "-")
        );
        const shuffled = relatedBlog.sort(() => 0.5 - Math.random());
        let selected = shuffled.slice(0, 4);
        if (selected.length > 0) {
            setRelatedBlog(selected);
        }
    }, []);
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Dr. Müjdegül Zayıfoğlu Karaca" />
                <div className="wrapper">
                    <Header />
                    <div className="container">
                        {basins != "" ? (
                            <div>
                                <section className="page-title centred">
                                    <div className="auto-container ">
                                        <div className="content-box mt-5">
                                            <h1>{basins.name}</h1>
                                        </div>
                                    </div>
                                </section>
                                {/*End Page Title*/}
                                {/* sidebar-page-container */}
                                <section className="sidebar-page-container blog-details">
                                    <div className="auto-container">
                                        <div className="row clearfix">
                                            <div className="col-lg-8 col-md-12 col-sm-12 content-side">
                                                <div className="blog-details-content">
                                                    <div className="news-block-one">
                                                        <div className="inner-box">
                                                            <figure className="image-box">
                                                                <img
                                                                    src={`https://api.mujdegulkaraca.com.tr/uploads/${basins.img}`}
                                                                    alt
                                                                />
                                                            </figure>
                                                            <div className="lower-content">
                                                                <span className="feature">
                                                                    {
                                                                        basins.categories
                                                                    }
                                                                </span>

                                                                <ul className="post-info clearfix">
                                                                    <li>
                                                                        <img
                                                                            style={{
                                                                                height:
                                                                                    "3vh",
                                                                            }}
                                                                            src="/img/blog/mujde.jpg"
                                                                            alt
                                                                        />
                                                                        DR.
                                                                        Müjdegül
                                                                        KARACA
                                                                    </li>
                                                                </ul>
                                                                <div className="text mt-3">
                                                                    <p>
                                                                        {ReactHtmlParser(
                                                                            basins.info
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="post-share-option mt-5 mb-5">
                                                        <ul className="clearfix">
                                                            <li>
                                                                <h5>
                                                                    <a
                                                                        href={
                                                                            basins.url
                                                                        }
                                                                    >
                                                                        Medya
                                                                        Linki
                                                                    </a>
                                                                </h5>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-3 offset-lg-1 col-md-12 col-sm-12 sidebar-side">
                                                <div className="sidebar">
                                                    <div className="sidebar-widget sidebar-post">
                                                        <div className="widget-title">
                                                            <h4>
                                                                Diğer Haberlerim
                                                            </h4>
                                                        </div>
                                                        {relatedBlog.map(
                                                            (key, index) => {
                                                                return (
                                                                    <div className="post-inner">
                                                                        <div className="post">
                                                                            <figure className="post-thumb">
                                                                                <a
                                                                                    href={`/basinda_ben/${key.basinNo}`}
                                                                                >
                                                                                    <img
                                                                                        src={`https://api.mujdegulkaraca.com.tr/uploads/${key.img}`}
                                                                                        alt
                                                                                    />
                                                                                </a>
                                                                            </figure>
                                                                            <h5>
                                                                                <a
                                                                                    href={`/basinda_ben/${key.basinNo}`}
                                                                                >
                                                                                    {
                                                                                        key.name
                                                                                    }
                                                                                </a>
                                                                            </h5>
                                                                            <span className="post-date">
                                                                                Nisan
                                                                                12,
                                                                                2020
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        ) : (
                            <>blog bulunamadı</>
                        )}
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

BasinDetails.propTypes = {
    basin: PropTypes.array,
};

const mapStateToProps = (state) => {
    return {
        basin: state.basinData.basin,
    };
};

export default connect(mapStateToProps)(BasinDetails);
