import React, { useState, useEffect } from "react";
import SectionTitle from "../../components/section-title";
import ServiceItem from "../../components/service-item";
import ServiceData from "../../data/service.json";
import Pagination from "rc-pagination";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";

const ServiceContainer = ({ basin }) => {
    const [collection, setCollection] = useState([]);
    const [resultLast, setResultLast] = useState([]);
    const [name, setName] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(9);

    useEffect(() => {
        document.title = "DR.MÜJDEGÜL KARACA | BLOG";
        setCollection(basin);
        setResultLast(basin.slice(0, count));
        let arr = [];
        let seen = new Set(),
            result = basin.filter(({ name }) => seen.add(name)),
            name = result.map((key) => {
                const obj = {
                    name: key.name,
                    img: key.img,
                    info: key.info,
                    blogNo: key.blogNo,
                    categories: key.categories,
                };
                arr.push(obj);
            });

        setName(arr);
    }, []);

    const updatePage = (pageNumber) => {
        const to = count * pageNumber;
        const from = to - count;
        setCurrentPage(pageNumber);
        setResultLast(collection.slice(from, to));
    };

    return (
        <div className="service-area">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-12">
                        <SectionTitle
                            classOption="text-center"
                            subTitle="Basın & Medya"
                            title="<span>Basında Yer Alan</span> Haberlerim"
                        />
                    </div>
                    <div className="row clearfix">
                        {resultLast.map((key, index) => {
                            return (
                                <div
                                    className="col-lg-4 col-md-6 col-sm-12 news-block"
                                    key={index}
                                >
                                    <div
                                        className="news-block-one wow fadeInUp animated animated"
                                        data-wow-delay="00ms"
                                        data-wow-duration="1500ms"
                                    >
                                        <div className="inner-box">
                                            <figure className="image-box d-flex justify-content-center">
                                                <a
                                                    href={`/basinda_ben/${key.basinNo}`}
                                                >
                                                    <img
                                                        src={`https://api.mujdegulkaraca.com.tr/uploads/${key.img}`}
                                                        alt=""
                                                    />
                                                </a>
                                            </figure>
                                            <div className="lower-content">
                                                <span className="feature">
                                                    {key.categories}
                                                </span>
                                                <h3>
                                                    <a
                                                        href={`/basinda_ben/${key.basinNo}`}
                                                    >
                                                        {key.name}
                                                    </a>
                                                </h3>
                                                <ul className="post-info clearfix">
                                                    <li>
                                                        <img
                                                            style={{
                                                                height: "3vh",
                                                            }}
                                                            src="/img/blog/mujde.jpg"
                                                            alt=""
                                                        />
                                                        Dr. Müjdegül KARACA
                                                    </li>
                                                    {/* <li>Nisan 18, 2020</li> */}
                                                </ul>
                                                <p>
                                                    {ReactHtmlParser(
                                                        key.info.slice(0, 150)
                                                    )}
                                                </p>

                                                <div className="btn-box ">
                                                    <a
                                                        href={key.url}
                                                        target={"_blank"}
                                                        rel="noreferrer"
                                                    >
                                                        Medya Linki
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="pagination-wrapper centred">
                        <Pagination
                            className="col-lg-12 text-center pagination d-flex justify-content-center"
                            pageSize={count}
                            onChange={updatePage}
                            current={currentPage}
                            total={collection.length}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

ServiceContainer.propTypes = {
    basin: PropTypes.array,
};

const mapStateToProps = (state) => {
    return {
        basin: state.basinData.basin,
    };
};

export default connect(mapStateToProps)(ServiceContainer);
