import React, { useState, useEffect } from "react";
import Pagination from "rc-pagination";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SectionTitle from "../../../components/section-title";


const cleanAndReplace = (str) => {
    return str
      .toLowerCase()
      .replace(/ğ/g, 'g')
      .replace(/ü/g, 'u')
      .replace(/ş/g, 's')
      .replace(/ı/g, 'i')
      .replace(/ö/g, 'o')
      .replace(/ç/g, 'c')
      .replace(/\s+/g, '-');
  };
const BlogListContainer = ({ blogs }) => {
    const [collection, setCollection] = useState([]);
    const [resultLast, setResultLast] = useState([]);
    const [name, setName] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(9);
    useEffect(() => {
        document.title = "DR.MÜJDEGÜL KARACA | BLOG";
        setCollection(blogs);
        setResultLast(blogs.slice(0, count));
        let arr = [];
        let seen = new Set(),
            result = blogs.filter(({ name }) => seen.add(name)),
            name = result.map((key) => {
                const obj = {
                    name: key.name,
                    img: key.img,
                    blogNo: key.blogNo,
                };
                arr.push(obj);
            });

        setName(arr);
    }, []);

    const updatePage = (pageNumber) => {
        const to = count * pageNumber;
        const from = to - count;
        setCurrentPage(pageNumber);
        setResultLast(collection.slice(from, to));
    };

    return (
        <div className="blog-area section-py blog-border-bottom">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-12">
                        <SectionTitle
                            classOption="text-center"
                            subTitle="Blog"
                            title="<span>Blog</span> Yazılarım"
                        />
                    </div>
                    <div className="col-lg-12">
                        <div className="row clearfix">
                            {resultLast.map((key, index) => {
                                return (
                                    <div
                                        className="col-lg-4 col-md-6 col-sm-12"
                                        key={index}
                                    >
                                        <div
                                            className="news-block-one "
                                            data-wow-delay="00ms"
                                            data-wow-duration="1500ms"
                                        >
                                            <div className="inner-box">
                                                <figure className="image-box d-flex justify-content-center">
                                                    <a
                                                     href={`/blog-detay/${cleanAndReplace(key.name)}`}

                                                    >
                                                        <img
                                                            src={`https://api.mujdegulkaraca.com.tr/uploads/${key.img}`}
                                                            alt=""
                                                        />
                                                    </a>
                                                </figure>
                                                <div className="lower-content">
                                                    <span className="feature">
                                                        {key.categories}
                                                    </span>
                                                    <h3>
                                                        <a
                                                            href={`/blog-detay/${key.blogNo}`}
                                                        >
                                                            {key.name}
                                                        </a>
                                                    </h3>
                                                    <ul className="post-info clearfix">
                                                        <li>
                                                            <img
                                                                style={{
                                                                    height:
                                                                        "3vh",
                                                                }}
                                                                src="/img/blog/mujde.jpg"
                                                                alt=""
                                                            />
                                                            Dr. Müjdegül KARACA
                                                        </li>
                                                        <li>{key.date}</li>
                                                    </ul>
                                              

                                                    <div className="btn-box">
                                                        <a
                                                            href={`/blog-detay/${key.blogNo}`}
                                                        >
                                                            Daha fazlası
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="pagination-wrapper ">
                            <Pagination
                                className="col-lg-12 text-center pagination d-flex justify-content-center"
                                pageSize={count}
                                onChange={updatePage}
                                current={currentPage}
                                total={collection.length}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

BlogListContainer.propTypes = {
    blogs: PropTypes.array,
};

const mapStateToProps = (state) => {
    return {
        blogs: state.blogData.blogs,
    };
};

export default connect(mapStateToProps)(BlogListContainer);
