import { useForm } from "react-hook-form";
import React, { Fragment, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AppointmentForm = () => {
    const form = useRef();
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const onSubmit = (data) => console.log(data);

    function sendEmail(e) {
        e.preventDefault();
        setFeedbackMessage("Mesajınız Gönderiliyor...");
        setIsButtonDisabled(true);

        emailjs
            .sendForm(
                "service_yxuvqjh",
                "template_oq09ool",
                form.current,
                "Ez74ypXK821SsshZJ"
            )
            .then((result) => {
                if (result.status === 200) {
                    setFeedbackMessage("Mesajınız Gönderilmiştir❤️");
                } else {
                    setFeedbackMessage("Mesajınız Gönderilemedi😓");
                }
            })
            .catch((err) => {
                setFeedbackMessage("Mesajınız Gönderilemedi😓");
            })
            .finally(() => {
                setTimeout(() => {
                    setIsButtonDisabled(false);
                }, 30000); // 30 saniye
            });
    }

    return (
        <Fragment>
            <ToastContainer />
            <form ref={form} onSubmit={sendEmail}>
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="text"
                                name="nameSurname"
                                placeholder="Ad-Soyad"
                                required="Ad-Soyad Bilgisi Gerekli!"
                            />
                            {errors.name && <p>{errors.name.message}</p>}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="email"
                                name="email"
                                placeholder="Email"
                                ref={register({
                                    required: "Email Bilgisi Gerekli",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "invalid email address",
                                    },
                                })}
                            />
                            {errors.email && <p>{errors.email.message}</p>}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="tel"
                                name="phone"
                                placeholder="Telefon"
                                required="Telefon Bilgisi Gerekli"
                            />
                            {errors.phone && <p>{errors.phone.message}</p>}
                        </div>
                    </div>
                  
                    <div className="col-md-12">
                        <div className="form-group mb-0">
                            <textarea
                                name="message"
                                rows="7"
                                placeholder="Mesaj"
                                required="Mesaj Bilgisi Gerekli"
                            ></textarea>
                            {errors.message && <p>{errors.message.message}</p>}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group mb-0">
                            <button
                                className="btn btn-theme"
                                type="submit"
                                id="form-submit"
                                disabled={isButtonDisabled}
                            >
                                Gönder
                            </button>
                        </div>
                        <p className="mt-5">{feedbackMessage}</p>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default AppointmentForm;
