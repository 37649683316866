import { FETCH_VAJINUSMUS_SUCCESS } from "../actions/vajinusmusAction";

const initState = {
    vajinusmus: [],
};

const vajinusmusReducer = (state = initState, action) => {
    if (action.type === FETCH_VAJINUSMUS_SUCCESS) {
        return {
            ...state,
            vajinusmus: action.payload,
        };
    }

    return state;
};

export default vajinusmusReducer;
