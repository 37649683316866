export const FETCH_BASIN_SUCCESS = "FETCH_BASIN_SUCCESS";

const fetchBasinSuccess = (basin) => ({
    type: FETCH_BASIN_SUCCESS,
    payload: basin,
});

// fetch products
export const fetchBasin = (basin) => {
    return (dispatch) => {
        dispatch(fetchBasinSuccess(basin));
    };
};
