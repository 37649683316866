import SectionTitle from "../../../components/section-title";
import HomeData from "../../../data/home.json";
import MedicalFeature from "../../../components/medical-feature";

const MedicalFeatureContainer = () => {
    return (
        <section className="feature-section bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            subTitle="Dr. Müjdegül ZAYIFOĞLU KARACA"
                            title="<span>Kadın Hastalıkları Doğum ve Tıbbi Biyoloji, </span>Genetik Uzmanı"
                        />
                    </div>
                    <div className="col-lg-7">
                        <p>
                            Vajinismus belirtileri yaşanıyorsa, uzman bir sağlık
                            profesyoneli ile görüşmek önemlidir. Kişiye özel
                            tedavi seçenekleri ile bu durum genellikle başarılı
                            bir şekilde yönetilebilir.
                        </p>
                        <a href="/vajinusmus">
                            <button
                                className="btn btn-theme mb-5"
                                type="submit"
                                id="form-submit"
                            >
                                Vajinusmus
                            </button>
                        </a>

                        <div
                            className="row icon-box-style"
                            data-aos="fade-up"
                            data-aos-duration="1100"
                        >
                            {HomeData[3].medicalFeature &&
                                HomeData[3].medicalFeature.map(
                                    (single, key) => {
                                        return (
                                            <div key={key} className="col-md-6">
                                                <MedicalFeature
                                                    key={key}
                                                    data={single}
                                                />
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                    </div>
                    <div
                        className=" col-lg-5 col-md-12 col-sm-12 d-flex align-items-center"
                        data-aos="fade-left"
                        data-aos-duration="2000"
                    >
                        <img
                            className="mujde-img"
                            src={process.env.PUBLIC_URL + "./img/DSCF0038.webp"}
                            alt="hope"
                        />
                    </div>
                </div>{" "}
            </div>
        </section>
    );
};

export default MedicalFeatureContainer;
