import React from "react";

const GoogleMap = () => {
    return (
        <iframe
            title="this is title"
            className="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.1165497044108!2d32.77003729999999!3d39.916407899999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d3488409f14539%3A0x2074aa4e390365c8!2sMustafa%20Kemal%2C%202141.%20Cd%20No%3A15%2C%2006510%20%C3%87ankaya%2FAnkara!5e0!3m2!1str!2str!4v1674465028852!5m2!1str!2str"
            aria-hidden="false"
        ></iframe>
    );
};

export default GoogleMap;
