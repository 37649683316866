import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import SocialIcon from "../../social-icon";
import Language from "./language";

const MobileMenu = ({ show, onClose }) => {
    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-expand") ||
            target.classList.contains("menu-expand")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };
    return (
        <div
            className={`offcanvas offcanvas-mobile-menu ${
                show ? "offcanvas-open" : ""
            }`}
        >
            <div className="inner">
                <Language />
                <div className="border-bottom mb-3 pb-3 text-end">
                    <button className="offcanvas-close" onClick={onClose}>
                        ×
                    </button>
                </div>
                <div className="offcanvas-head mb-3">
                    <div className="header-top-offcanvas">
                        <p>
                            <i className="icofont-google-map"></i>{" "}
                            <span>ADRES:</span> Mustafa Kemal Mah. 2141. Cd.
                            No:15/1 Çankaya/ANKARA
                        </p>
                    </div>
                </div>
                <nav className="offcanvas-menu">
                    <ul>
                        <li>
                            <NavLink exact to={process.env.PUBLIC_URL + "/"}>
                                <span className="menu-text">Anasayfa</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={process.env.PUBLIC_URL + "/hakkimda"}>
                                Hakkımda
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={process.env.PUBLIC_URL + "/basin-medya"}
                            >
                                <span className="menu-text">Basın & Medya</span>
                            </NavLink>
                            {/* <span
                                className="menu-expand"
                                onClick={onClickHandler}
                                aria-hidden="true"
                            ></span>
                            <ul className="offcanvas-submenu">
                                <li>
                                    <NavLink
                                        to={process.env.PUBLIC_URL + "/service"}
                                    >
                                        Service
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/service-detalis"
                                        }
                                    >
                                        service details
                                    </NavLink>
                                </li>
                            </ul>*/}
                        </li>

                        <li>
                            <NavLink to={process.env.PUBLIC_URL + "/blog"}>
                                <span className="menu-text">Blog</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={
                                    process.env.PUBLIC_URL +
                                    "/sikca_sorulan_sorular"
                                }
                            >
                                <span className="menu-text">SSS</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to={process.env.PUBLIC_URL + "/iletisim"}>
                                İletişim
                            </NavLink>
                        </li>
                        <li>   <a href="https://api.whatsapp.com/send/?phone=905344250343"><button style={{backgroundColor:"#25D366",color:"white",border:"none",borderRadius:"5px"}}>WHATSAPP</button></a></li>
                    </ul>
                </nav>
                <div className="offcanvas-social my-4">
                    <ul>
                        <li>
                            <SocialIcon
                                path="https://twitter.com/mujdegulkaraca"
                                icon="icofont-twitter"
                            />
                        </li>
                        <li>
                            <SocialIcon
                                path="https://www.facebook.com/"
                                icon="icofont-facebook"
                            />
                        </li>
                        <li>
                            <SocialIcon
                                path="https://www.instagram.com/drmujdegulzayifoglukaraca/"
                                icon="icofont-instagram"
                            />
                        </li>

                        <li>
                            <SocialIcon
                                path="https://www.youtube.com/"
                                icon="icofont-play-alt-1"
                            />
                        </li>
                    </ul>
                </div>

                <ul className="media-wrap">
                    <li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-clock-time"></i>
                        </span>
                        <div className="media-content">
                            <span className="media-sub-heading">
                                Çalışma Saatleri
                            </span>
                            <span className="media-heading">
                                Pazt - Cuma: 09.00 - 17.00{" "}
                            </span>
                        </div>
                    </li>

                    <li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-ui-call"></i>
                        </span>
                        <div className="media-content">
                            <span className="media-sub-heading">Telefon</span>
                            <a
                                className="media-heading"
                                href="tel:+905344250343"
                            >
                                +90 534 425 03 43
                            </a>
                        </div>
                    </li>

                    <li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-envelope"></i>
                        </span>
                        <div className="media-content">
                            <span className="media-sub-heading">Email</span>
                            <a
                                className="media-heading"
                                href="mailto:bilgi@drmujdegul.com"
                            >
                                bilgi@drmujdegul.com
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

MobileMenu.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

export default MobileMenu;
