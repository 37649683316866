import SocialIcon from "../../components/social-icon";
import { Link } from "react-router-dom";
import Newsletter from "../../components/newsletter";
import Logo from "../../components/logo";

const Footer = () => {
    return (
        <footer className="footer-area footer-bottom">
            <div className="container footer-bottom">
                <div className="row">
                    <div className="col-sm-6 col-md-5 col-lg-4 col-xl-6">
                        <div className="widget-item">
                            <div className="about-widget">
                                <Logo
                                    classOption="footer-logo"
                                    image={`${process.env.PUBLIC_URL}/img/mujdegul_logo_black.webp`}
                                />
                                <p
                                    className="mb-0"
                                    style={{ textAlign: "center" }}
                                >
                                  Kadın Hastalıkları ve Doğum Uzmanı Ankara
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 offset-md-1 col-lg-4 offset-lg-0 col-xl-6 d-lg-none d-xl-block">
                        <div className="widget-item d-inline-block">
                            <h4 className="widget-title line-bottom">
                                İletişim Bilgileri
                            </h4>
                            <div className="widget-tags">
                                <ul className="widget-contact-info">
                                    <li className="info-address">
                                        <i className="icofont-location-pin"></i>
                                        Mustafa Kemal Mah. 2141. Cd. No:15/1
                                        Çankaya/ANKARA
                                    </li>
                                    <li className="info-mail">
                                        <i className="icofont-email"></i>
                                        <a href="mailto:bilgi@drmujdegul.com">
                                            bilgi@drmujdegul.com
                                        </a>
                                    </li>
                                    <li className="info-phone">
                                        <i className="icofont-ui-call"></i>
                                        <a href="tel:+905344250343">
                                            +90 534 425 03 43
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-sm-12">
                            <div className="widget-copyright">
                                <p>
                                    &copy; 2024 - Ankara Kadın Doğum Uzmanı - Made with{" "}
                                    <i className="icofont-heart-alt"></i> by{" "}
                                    <a
                                        aria-label="osicrew"
                                        target="_blank"
                                        href="https://www.osicrew.com"
                                        rel="noreferrer"
                                    >
                                        OsiCrew
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
