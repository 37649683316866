import React from "react";
import { Link } from "react-router-dom";

const ContactAddress = () => {
    return (
        <div className="contact-info-content">
            <div className="info-address">
                <h2 className="title">
                    Ankara, <span>Çankaya</span>
                </h2>
                <p>Mustafa Kemal Mah. 2141. Cd. No:15/1 Çankaya/ANKARA</p>
                <span
                    style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#99cccc",
                    }}
                >
                    Email:
                </span>
                <a href="mailto:bilgi@drmujdegul.com">bilgi@drmujdegul.com</a>{" "}
            </div>
            <div className="brand-office">
                <div className="info-tem style-two">
                    <h6>Telefon:</h6>{" "}
                    <a
                        style={{ color: "white", fontSize: "16px" }}
                        href="tel:905344250343"
                    >
                        +90 534 425 03 43
                    </a>
                </div>

                <div className="info-tem mb-0">
                    <h6>Çalışma Saatleri:</h6>
                    <p>Pazartesi - Cuma: 09.00 - 17.00</p>
                </div>
            </div>
        </div>
    );
};

export default ContactAddress;
