import React from "react";
import BlogGrid from "../../../components/blog-grid";
import BlogDetailsWrap from "../../../components/blog-details";
import PropTypes from "prop-types";

const BlogDetailsContainer = ({ data }) => {
    return (
        <div className="blog-details-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="post-details-content">
                            <div className="post-details-body">
                                <BlogDetailsWrap data={data} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

BlogDetailsContainer.propTypes = {
    data: PropTypes.object,
};

export default BlogDetailsContainer;
