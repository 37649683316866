export const FETCH_VAJINUSMUS_SUCCESS = "FETCH_VAJINUSMUS_SUCCESS";

const fetchVajinusmusSuccess = (vajinusmus) => ({
    type: FETCH_VAJINUSMUS_SUCCESS,
    payload: vajinusmus,
});

// fetch products
export const fetchVajinusmus = (vajinusmus) => {
    return (dispatch) => {
        dispatch(fetchVajinusmusSuccess(vajinusmus));
    };
};
