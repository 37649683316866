import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers/rootReducer";
// import blogs from "./data2/blog.json";
// import vajinusmus from "./data2/vajinusmus.json";
// import basin from "./data2/basin.json";
// import sss from "./data2/sss.json";
import reportWebVitals from "./reportWebVitals";
import { fetchBlogs } from "./redux/actions/blogAction";
import { fetchBasin } from "./redux/actions/basinAction";
import { fetchVajinusmus } from "./redux/actions/vajinusmusAction";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import axios from "axios";
import { fetchSSS } from "./redux/actions/sssAction";

const store = createStore(
    rootReducer,
    load(),
    composeWithDevTools(applyMiddleware(thunk, save()))
);
// store.dispatch(fetchBlogs(blogs));
// store.dispatch(fetchBasin(basin));
// store.dispatch(fetchVajinusmus(vajinusmus));
// store.dispatch(fetchSSS(sss));
axios
    .get("https://api.mujdegulkaraca.com.tr/blog")
    .then((res) => {
        store.dispatch(fetchBlogs(res.data));
    })
    .catch((err) => {
        console.log(err.response);
    });

axios
    .get("https://api.mujdegulkaraca.com.tr/basin")
    .then((res) => {
        store.dispatch(fetchBasin(res.data));
    })
    .catch((err) => {
        console.log(err.response);
    });

axios
    .get("https://api.mujdegulkaraca.com.tr/vajinusmus")
    .then((res) => {
        store.dispatch(fetchVajinusmus(res.data));
    })
    .catch((err) => {
        console.log(err.response);
    });
axios
    .get("https://api.mujdegulkaraca.com.tr/sss")
    .then((res) => {
        store.dispatch(fetchSSS(res.data));
    })
    .catch((err) => {
        console.log(err.response);
    });

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
