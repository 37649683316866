import { FETCH_BASIN_SUCCESS } from "../actions/basinAction";
const initState = {
    basin: [],
};
const basinReducer = (state = initState, action) => {
    if (action.type === FETCH_BASIN_SUCCESS) {
        return {
            ...state,
            basin: action.payload,
        };
    }

    return state;
};

export default basinReducer;
